@tailwind base;
@tailwind components;
@tailwind utilities;

@import '@splidejs/react-splide/css/core';
@import 'aos/dist/aos.css';

* {
  font-family: 'Cairo';
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.app-container {
  @apply m-auto mx-auto w-full lg:max-w-[1000px] xl:max-w-[1200px];
}
article ul,
article ol {
  list-style: revert;
  margin: revert;
  padding: revert;
  @apply space-y-2 text-lg;
}

article a {
  text-decoration: underline;
}

article p {
  @apply !text-justify text-lg leading-loose;
}

article table {
  @apply border-muted-300 w-full border-2 text-lg;
}

article table tr {
  @apply border-muted-300 border-b-2 even:bg-light;
}

article table tr th {
  @apply bg-primary-600 whitespace-nowrap px-6 py-4 text-lg text-white;
}

article table tr td {
  @apply px-6 py-4;
}

* {
  box-sizing: border-box; /* Ensure correct sizing */
}

.container {
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  padding: 0; /* Adjust as needed */
  margin: 0; /* Adjust as needed */
}

::-webkit-scrollbar {
  width: 8px; /* Adjusted scrollbar width */
  height: 8px; /* For horizontal scrollbar */
}

::-webkit-scrollbar-track {
  background: #f6f9fc; /* Very light gray-blue */
}

::-webkit-scrollbar-thumb {
  background-color: #e0ebef; /* Light gray-blue */
  border-radius: 6px;
  border: 2px solid transparent;
  background-clip: padding-box;
  transition: background-color 0.3s ease; /* Smooth transition */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #dfb662; /* Secondary color on hover */
}

::-webkit-scrollbar-thumb:active {
  background-color: #dfb662; /* Secondary color on click */
}

* {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #dfb662 #f6f9fc; /* thumb color / track color */
}
